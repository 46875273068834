import { gql } from 'graphql-tag'
import { CheckoutResponse } from '../../types'

export interface CartDiscountCodesUpdateInput {
  cartId: string
  discountCodes: string[]
}

export type CartDiscountCodesUpdateResponse =
  CheckoutResponse<'cartDiscountCodesUpdate'>

export const CART_DISCOUNT_CODES_UPDATE = gql`
  mutation cartDiscountCodesUpdate(
    $cartId: ID!
    $input: CartDiscountCodesUpdateInput!
  ) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      userErrors {
        code
        field
        message
      }
      cart {
        discountCodes {
          code
          applicable
        }
        discountAllocations {
          discountedAmount {
            amount
          }
        }
        cost {
          subtotalAmount {
            amount
          }
        }
      }
    }
  }
`
