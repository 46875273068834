import { gql } from 'graphql-tag'
import { AttributeInput, CartResponse, CartLineInput } from '../../types'
import { cartFragment } from '../../../../graphql'
import { ShopifyStorefrontCountryCode } from '../../../../types/generated-shopify'

export interface CartCreateInput {
  email?: string
  lines?: CartLineInput[]
  note?: string
  attributes?: AttributeInput[]
  countryCode: ShopifyStorefrontCountryCode
}

export type CartCreate = (input: CartCreateInput) => CartCreateResponse

export type CartCreateResponse = CartResponse<'cartCreate'>

export const CART_CREATE = gql`
  mutation cartCreate(
    $email: String
    $lines: [CartLineInput!]
    $note: String
    $customAttributes: [AttributeInput!]
    $countryCode: CountryCode!
  ) @inContext(country: $countryCode) {
    cartCreate(
      input: {
        buyerIdentity: { countryCode: $countryCode, email: $email }
        lines: $lines
        note: $note
        attributes: $customAttributes
      }
    ) {
      userErrors {
        code
        field
        message
      }
      cart {
        ...CartFragment
      }
    }
  }
  ${cartFragment}
`
