import { gql } from 'graphql-tag'
import { CartLineInput, CartResponse } from '../../types'
import { cartFragment } from '../../../../graphql'

export interface CartLinesAddInput {
  cartId: string
  lines: CartLineInput[]
}

export type CartLinesAddResponse = CartResponse<'cartLinesAdd'>

export const CART_LINES_ADD = gql`
  mutation CartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      userErrors {
        __typename
        code
        field
        message
      }
      cart {
        ...CartFragment
      }
    }
  }
  ${cartFragment}
`
