import algoliaSearch from 'algoliasearch'

const INDEX_NAME = 'Storefront Search'

const ALGOLIA_API_KEY =
  typeof window === 'undefined'
    ? process.env.ALGOLIA_ADMIN_KEY
    : process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY

export const algoliaClient = algoliaSearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
  ALGOLIA_API_KEY || '',
)

export const algoliaIndex = algoliaClient.initIndex(INDEX_NAME)
