import * as React from 'react'
import { Heading } from '../../Text'
import { Button, LinkButton } from '../../Button'
import { Form } from '../Form'
import { Field } from '../Fields/Field'
import { SignupModalSettings } from '../../../types/generated-sanity'
import { PortableText, PortableTextComponents } from '@portabletext/react'
import Link from 'next/link'
import * as Yup from 'yup'
import { useUser } from '../../../providers/UserProvider'
import { useAnalytics } from '../../../providers/AnalyticsProvider'
import { useRouter } from 'next/router'
import { Sentry } from '../../../services/sentry'

import {
  SignupWrapper,
  SuccessWrapper,
  ConsentWrapper,
  FieldsWrapper as BaseFieldsWrapper,
  SignupFieldsWrapper,
  InputWrapper,
} from './styled'
import { getCookie, setCookie } from '../../../utils'
import { SignupFormProps } from '../../../types/modal'
import { styled, css } from '@xstyled/styled-components'

import { generateEmailUUID } from '../../../providers/UserProvider/utils'
import { SeverityLevel } from '@sentry/browser'

const { useState, useEffect } = React

type FormValues = {
  email?: string
  phone?: string
  phoneCountryCode?: string
  dialingCode?: string
  smsConsent: boolean
  emailConsent: boolean
}

const VipOptOutLabel = styled.span`
  font-style: italic;
  color: red;
  display: inline;
  font-weight: 300;
`

export const SignupForm = ({ onContinue, settings }: SignupFormProps) => {
  const { changeUser, getUser, openSession, logCustomEvent, geoLocation } =
    useUser()
  const { sendFormSubmit } = useAnalytics()
  const router = useRouter()
  const { pathname, asPath } = router

  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [formState, setFormState] = useState<FormValues>({
    smsConsent: true,
    emailConsent: true,
    email: '',
    phone: '',
    phoneCountryCode: 'US-United States',
    dialingCode: '1',
  })
  const [vipOptOutEmail, setVipOptOutEmail] = useState(false)
  const [vipOptOutSms, setVipOptOutSms] = useState(false)
  const [submitText, setSubmitText] = useState('Unlock VIP Rewards')
  const [submittedSubheading, setSubmittedSubheading] = useState(
    'Please confirm your subscription.',
  )
  const [error, setError] = useState<string | null>(null)

  const {
    heading,
    subheadingRaw,
    footerText,
    links,
    heading_submitted,
    subheading_submitted_vip,
    subheading_submitted_email,
    subheading_submitted_sms,
    submittedButtonLink,
    submittedButtonText,
  } = settings || {}

  const handleSubmit = async (values: FormValues) => {
    setSubmitting(true)
    setError(null)

    const user = getUser()

    try {
      const strippedPhoneNumber = values.phone?.replace(/[^0-9]/g, '')
      const formattedPhoneNumber = `+${values.dialingCode}${strippedPhoneNumber}`

      // Add breadcrumb for debugging
      Sentry.addBreadcrumb({
        category: 'form',
        message: 'User attempted signup form submission',
        level: 'info' as SeverityLevel,
        data: {
          email: values.email,
          phone: values.phone,
          emailConsent: values.emailConsent,
          smsConsent: values.smsConsent,
        },
      })

      if (!values.emailConsent && values.smsConsent && values.phone) {
        // If no email consent, we are only sending a phone number to Braze
        const userPhoneNumberSet = user.setPhoneNumber(formattedPhoneNumber)
        // add user to SMS subscription group
        const userSubscribed = user.addToSubscriptionGroup(
          'fe589b39-05c1-45d9-8efe-a9bd9e6a2f91',
        )
        console.log('user subscribed to sms group', userSubscribed)
        setSuccess(true)
        setSubmitting(false)
        return
      } else if (values.email && values.emailConsent) {
        const emailUUID = await generateEmailUUID(values.email)

        await changeUser(emailUUID)

        const userOptedIn =
          user.setEmailNotificationSubscriptionType('opted_in')
        console.log('user opted in', userOptedIn)

        const userSubscribedEmail = user.addToSubscriptionGroup(
          'dd1fcdc9-b13d-4c5a-8f5f-252a2da00bd9',
        )
        console.log('user subscribed to email group', userSubscribedEmail)

        if (values.phone && values.smsConsent) {
          const userPhoneNumberSet = user.setPhoneNumber(formattedPhoneNumber)
          // add user to SMS subscription group
          const userSubscribed = user.addToSubscriptionGroup(
            'fe589b39-05c1-45d9-8efe-a9bd9e6a2f91',
          )
          console.log('user subscribed to sms group', userSubscribed)
        }

        openSession()

        await fetch('/api/hubspotUserSignup', {
          method: 'POST',
          body: JSON.stringify(values),
        }).then((r) => {
          return r.json()
        })

        setSuccess(true)
        setSubmitting(false)
      }
    } catch (error) {
      setSubmitting(false)
      setError('Something went wrong. Please try again.')

      Sentry.captureException(error, 'signup_form_error', {
        formValues: values,
        pathname,
        asPath,
        type: 'form',
        country: getCookie('VIEWER_COUNTRY'),
        subscriptionType:
          formState.emailConsent && formState.smsConsent
            ? 'vip'
            : formState.emailConsent
            ? 'email'
            : 'sms',
      })
    }
  }

  const initialValues = {
    email: '',
    phone: '',
    smsConsent: true,
    emailConsent: true,
    phoneCountryCode: 'US-United States',
    dialingCode: '',
  }

  const validationSchema = Yup.object()
    .shape({
      emailConsent: Yup.boolean(),
      smsConsent: Yup.boolean(),
      email: Yup.string().when('emailConsent', {
        is: true,
        then: (schema) =>
          schema.email('Invalid email address').required('Email is required'),
        otherwise: (schema) => schema.notRequired(),
      }),
      phone: Yup.string().when('smsConsent', {
        is: true,
        then: (schema) => schema.required('Phone number is required'),
        otherwise: (schema) => schema.notRequired(),
      }),
    })
    .test(
      'at-least-one-consent',
      'You must opt-in to at least one of email or SMS',
      (values) => values.emailConsent || values.smsConsent,
    )

  useEffect(() => {
    if (formState.smsConsent && !formState.emailConsent) {
      setSubmitText('Sign Up for SMS Only')
      setSubmittedSubheading(
        subheading_submitted_sms ||
          'Please confirm your subscription by checking your text messages.',
      )
      setVipOptOutEmail(true)
    } else if (!formState.smsConsent && formState.emailConsent) {
      setSubmitText('Sign Up for Email Only')
      setSubmittedSubheading(
        subheading_submitted_email ||
          'Please confirm your subscription by checking your email.',
      )
      setVipOptOutSms(true)
    } else if (!formState.emailConsent && !formState.smsConsent) {
      setSubmitText('Unlock VIP Rewards')
      setVipOptOutEmail(false)
      setVipOptOutSms(false)
    } else {
      setVipOptOutEmail(false)
      setVipOptOutSms(false)
      setSubmitText('Unlock VIP Rewards')
      setSubmittedSubheading(
        subheading_submitted_vip ||
          'Please confirm your subscription by checking both your email inbox and text messages.',
      )
    }
  }, [
    formState,
    subheading_submitted_email,
    subheading_submitted_sms,
    subheading_submitted_vip,
  ])

  useEffect(() => {
    const logSignupEvent = () => {
      if (success) {
        sendFormSubmit('web_welcome_24')
        logCustomEvent('complete_form', {
          id: 'web_welcome_24',
          path: asPath,
          country:
            geoLocation?.country || getCookie('VIEWER_COUNTRY') || undefined,
          city: geoLocation?.city || undefined,
          subscribe:
            formState.emailConsent && formState.smsConsent
              ? 'vip'
              : formState.emailConsent
              ? 'email'
              : 'sms',
          phoneRegion:
            formState.dialingCode === '1' ? 'domestic' : 'international',
        })
        // Set cookie to prevent modal from opening automatically again
        setCookie('SK_SIGNUP_SUBMITTED', 'true', {
          expires: 365,
        })
      }
    }

    logSignupEvent()
  }, [success])

  const handleFormStateChange = (values: FormValues) => {
    setFormState(values)
  }

  const handleContinueClick = () => {
    onContinue && onContinue()
  }

  const colorTheme = 'light'

  return (
    <SignupWrapper $colorTheme={colorTheme}>
      <Heading mt={0} mb={2} level={3}>
        {success
          ? heading_submitted || 'Thank you!'
          : heading || 'Join Our Circle'}
      </Heading>
      {!success && <PortableText value={subheadingRaw} />}
      {success && <Heading level={4}>{submittedSubheading}</Heading>}

      <Form
        id="signup-form"
        disabled={submitting}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFormStateChange={handleFormStateChange}
      >
        <SignupFieldsWrapper $visible={!success} $colorTheme={colorTheme}>
          <InputWrapper $colorTheme={colorTheme}>
            <Field name="emailConsent" type="checkbox" />
            <Field
              name="email"
              type="email"
              placeholder="Email Address"
              label={
                vipOptOutEmail ? (
                  <VipOptOutLabel>
                    You are opting out of VIP access
                  </VipOptOutLabel>
                ) : (
                  'Email'
                )
              }
              disabled={!formState.emailConsent}
              required={formState.emailConsent}
            />
          </InputWrapper>
          <InputWrapper $colorTheme={colorTheme} className="last-row">
            <Field name="smsConsent" type="checkbox" />
            <Field
              name="phone"
              type="tel"
              placeholder="Phone"
              label={
                vipOptOutSms ? (
                  <VipOptOutLabel>
                    You are opting out of VIP access
                  </VipOptOutLabel>
                ) : (
                  'Phone'
                )
              }
              required={formState.smsConsent}
              disabled={!formState.smsConsent}
            />
          </InputWrapper>
          {!success && (
            <Button
              type="submit"
              disabled={!formState.emailConsent && !formState.smsConsent}
            >
              {submitText}
            </Button>
          )}
          {error && (
            <h5
              style={{
                color: 'red',
                fontWeight: 300,
                position: 'absolute',
                marginTop: '-24px',
                fontStyle: 'italic',
              }}
            >
              {error}
            </h5>
          )}
          {success && (
            <Button
              onClick={handleContinueClick}
              type="button"
              mt={3}
              level={3}
            >
              {submittedButtonText}
            </Button>
          )}
          <ConsentWrapper>
            {footerText}
            <div className="links-wrapper">
              <Link href="/about/privacy-policy" target="_blank">
                Privacy Policy
              </Link>
              <Link href="/about/terms-and-conditions" target="_blank">
                Terms & Conditions
              </Link>
            </div>
          </ConsentWrapper>
        </SignupFieldsWrapper>
      </Form>
    </SignupWrapper>
  )
}
