import Debug from 'debug'
import axios from 'axios'
import { Sentry } from './sentry'

const debug = Debug('app:hubspotCRM')

interface HubspotResponse {
  success: boolean
  error?: Error
  vid?: string // HubSpot's contact ID
}

interface HubspotService {
  createOrUpdate: (input: CreateOrUpdateInput) => Promise<HubspotResponse>
}

interface CreateOrUpdateInput {
  email: string
  phone?: string
  emailConsent: boolean
  smsConsent: boolean
}

const client = axios.create({
  baseURL: 'https://api.hubapi.com',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.HUBSPOT_ACCESS_TOKEN}`,
  },
})

export const hubspotCRM: HubspotService = {
  createOrUpdate: async (input: CreateOrUpdateInput) => {
    try {
      const { email, phone, emailConsent, smsConsent } = input

      // Format properties for HubSpot's v1 API
      const properties = [
        ...(email ? [{ property: 'email', value: email }] : []),
        ...(phone ? [{ property: 'phone', value: phone }] : []),
        ...[{ property: 'braze_sms_opt_in', value: smsConsent }],
        ...[{ property: 'braze_email_opt_in', value: emailConsent }],
      ]

      // Use v1 createOrUpdate endpoint which is most efficient for email-based upserts
      const response = await client.post(
        `/contacts/v1/contact/createOrUpdate/email/${email}`,
        { properties },
      )

      debug(`Successfully created or updated contact: ${email}`)
      return {
        success: true,
        vid: response.data.vid,
      }
    } catch (error: any) {
      debug(
        'Error creating or updating contact:',
        error.response?.data || error.message,
      )
      Sentry.captureException(error, 'hubspot_crm_error', { input })
      return {
        success: false,
        error,
      }
    }
  },
}
