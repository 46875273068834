import { CartAttributesUpdateInput, CartCreateInput } from './queries'

import { UserError, CartLineInput, CartLineUpdateInput, Cart } from '../types'

export const STARTED_REQUEST = 'STARTED_REQUEST'
export const APPLIED_DISCOUNT = 'APPLIED_DISCOUNT'
export const REMOVED_DISCOUNT = 'REMOVED_DISCOUNT'
export const ADDED_LINE_ITEMS = 'ADDED_LINE_ITEMS'
export const UPDATED_LINE_ITEMS = 'UPDATED_LINE_ITEMS'
export const RECEIVED_ERRORS = 'RECEIVED_ERRORS'
export const CART_CLEARED = 'CART_CLEARED'
export const ADDED_NOTE = 'ADDED_NOTE'
export const ADDED_ATTRIBUTES = 'ADDED_ATTRIBUTES'
export const FETCHED_CART = 'FETCHED_CART'
export const CREATED_CART = 'CREATED_CART'

/**
 * State
 */

//-------------------------------------------------------
interface CartAndErrors {
  cart?: Cart
  userErrors?: UserError[]
}

export interface CartState {
  loading: boolean
  ready: boolean
  userErrors: UserError[]
  cart: Cart | void
}

export interface UseCartValues extends CartState {
  /* All of CartState, and */
  /* Base Methods */
  cartCreate: (args: CartCreateInput) => Promise<CartAndErrors>
  cartLineItemsAdd: (lineItems: CartLineInput[]) => Promise<void>
  cartLineItemsUpdate: (lineItems: CartLineUpdateInput[]) => Promise<void>
  cartDiscountCodesUpdate: (discountCodes: string[]) => Promise<void>
  cartAttributesUpdate: (args: CartAttributesUpdateInput) => Promise<void>
  cartNoteUpdate: (args: string) => Promise<void>

  /* Shortcut Methods */
  addLineItem: (lineItem: CartLineInput) => Promise<void>
  updateLineItem: (lineItem: CartLineUpdateInput) => Promise<void>
  clearCart: () => Promise<void>
  refetchCart: () => Promise<void>
  addNote: (note: string) => Promise<void>
  addAttributes: (input: CartAttributesUpdateInput) => Promise<void>
}
