import { gql } from 'graphql-tag'
import { AttributeInput, CartResponse } from '../../types'
import { cartFragment } from '../../../../graphql'

export interface CartAttributesUpdateInput {
  attributes: [AttributeInput]
}

export interface CartAttributesUpdateArgs {
  cartId: string
  attributes: AttributeInput[]
}

export type CartAttributesUpdateResponse = CartResponse<'cartAttributesUpdate'>

export const CART_ATTRIBUTES_UPDATE = gql`
  mutation AddAttributes($cartId: ID!, $attributes: [AttributeInput!]!) {
    cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
      userErrors {
        __typename
        code
        field
        message
      }
      cart {
        ...CartFragment
      }
    }
  }
  ${cartFragment}
`
