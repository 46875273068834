import * as React from 'react'
import { useAnnouncement } from '../providers/AnnouncementProvider'
import { styled } from '@xstyled/styled-components'

interface OffsetContextValue {
  offset: string
}

const OffsetContext = React.createContext<OffsetContextValue>({ offset: '0' })

export const useOffset = () => React.useContext(OffsetContext)

const OffsetRoot = styled.div<{ offset: string }>`
  padding-top: ${({ offset }) => offset};
  transition: padding-top 0.3s ease;
`

export const AnnouncementOffset: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { visible } = useAnnouncement()
  const [scrolled, setScrolled] = React.useState(false)

  React.useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 40
      setScrolled(isScrolled)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const offset = !visible || scrolled ? '0' : '40px'

  return (
    <OffsetContext.Provider value={{ offset }}>
      <OffsetRoot offset={offset}>{children}</OffsetRoot>
    </OffsetContext.Provider>
  )
}
