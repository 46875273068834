import * as React from 'react'
import { Modal } from '../Modal'
import { ProductInquiryForm } from '../Forms/Forms'
import { ProductInquiryModalProps } from '../../types/modal'

export const ProductInquiryModal = ({
  closeModal,
  product,
  variant,
}: ProductInquiryModalProps) => {
  return (
    <Modal closeModal={closeModal}>
      <ProductInquiryForm product={product} variant={variant} />
    </Modal>
  )
}
