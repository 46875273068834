import { Cart, UserError } from '../types'

import {
  STARTED_REQUEST,
  APPLIED_DISCOUNT,
  REMOVED_DISCOUNT,
  ADDED_LINE_ITEMS,
  UPDATED_LINE_ITEMS,
  CART_CLEARED,
  RECEIVED_ERRORS,
  CartState,
  FETCHED_CART,
  CREATED_CART,
  ADDED_ATTRIBUTES,
  ADDED_NOTE,
} from './types'

interface StartedAction {
  type: typeof STARTED_REQUEST
}

interface GenericActionCart {
  type:
    | typeof FETCHED_CART
    | typeof CREATED_CART
    | typeof APPLIED_DISCOUNT
    | typeof REMOVED_DISCOUNT
    | typeof ADDED_LINE_ITEMS
    | typeof UPDATED_LINE_ITEMS
    | typeof RECEIVED_ERRORS
    | typeof CART_CLEARED
    | typeof ADDED_ATTRIBUTES
    | typeof ADDED_NOTE
  cart?: Cart
  userErrors?: UserError[]
}

export type CartAction = StartedAction | GenericActionCart

export const cartReducer = (
  state: CartState,
  action: CartAction,
): CartState => {
  switch (action.type) {
    case STARTED_REQUEST:
      return { ...state, loading: true }
    case CART_CLEARED:
      return {
        ...state,
        cart: undefined,
        userErrors: [],
        loading: false,
        ready: true,
      }
    case FETCHED_CART:
    case CREATED_CART:
    case APPLIED_DISCOUNT:
    case REMOVED_DISCOUNT:
    case ADDED_LINE_ITEMS:
    case UPDATED_LINE_ITEMS:
    case ADDED_ATTRIBUTES:
    case ADDED_NOTE:
    case RECEIVED_ERRORS:
      const { userErrors, cart } = action
      return {
        ...state,
        userErrors: userErrors || [],
        cart,
        loading: false,
        ready: true,
      }
    default:
      return state
  }
}
