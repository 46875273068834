import { CART_DISCOUNT_CODES_UPDATE } from './cartDiscountCodesUpdate'
import { CART_LINES_UPDATE } from './cartLinesUpdate'
import { CART_LINES_ADD } from './cartLinesAdd'
import { CART_FETCH } from './cartFetch'
import { CART_CREATE } from './cartCreate'
import { CART_ATTRIBUTES_UPDATE } from './cartAttributesUpdate'
import { CART_NOTE_UPDATE } from './cartNoteUpdate'

export * from './cartCreate'
export * from './cartLinesAdd'
export * from './cartLinesUpdate'
export * from './cartFetch'
export * from './cartAttributesUpdate'
export * from './cartNoteUpdate'

export const defaultQueries = {
  CART_CREATE,
  CART_DISCOUNT_CODES_UPDATE,
  CART_LINES_ADD,
  CART_LINES_UPDATE,
  CART_FETCH,
  CART_ATTRIBUTES_UPDATE,
  CART_NOTE_UPDATE,
}
