import * as React from 'react'
import { unwindEdges } from '@good-idea/unwind-edges'
import { UseCartValues } from './useCheckout'
import { useAnalytics } from '../AnalyticsProvider'
import { QueryFunction } from './types'
import {
  useCartCheckout,
  UseCartCheckoutConfig,
  UseCartCheckoutQueries,
} from './useCheckout/useCartCheckout'

export interface ShopifyContextValue extends UseCartValues {
  goToCheckout: () => void
}

export const ShopifyContext = React.createContext<
  ShopifyContextValue | undefined
>(undefined)

export const ShopifyConsumer = ShopifyContext.Consumer

export const useShopify = () => {
  const ctx = React.useContext(ShopifyContext)
  if (!ctx)
    throw new Error('`useShopify` must be used within a ShopifyProvider')
  return ctx
}

type CustomQueries = Partial<UseCartCheckoutQueries>

interface Props {
  children: React.ReactNode
  query: QueryFunction
  queries?: CustomQueries
  config?: Partial<UseCartCheckoutConfig>
}

const defaultConfig = {
  cart: undefined,
}

export const ShopifyProvider = ({
  children,
  queries,
  query,
  config: userConfig,
}: Props) => {
  const { sendBeginCheckout } = useAnalytics()
  const config = {
    ...defaultConfig,
    ...userConfig,
  }

  const useCartValues = useCartCheckout({
    queries,
    query,
    config: config ? config : undefined,
  })

  const goToCheckout = () => {
    const { cart } = useCartValues
    if (!cart) {
      throw new Error('No checkout has been initiated')
    }
    const [lines] = unwindEdges(cart.lines)
    const totalValue = cart.cost.totalAmount.amount
    /* Send the analytics event */
    sendBeginCheckout(
      lines.map((li) => ({
        product: li.merchandise.product,
        variant: li.merchandise,
        quantity: li.quantity,
      })),
      totalValue,
    )
    const { protocol, pathname, search } = new URL(cart.checkoutUrl)
    const redirect: string = `${protocol}//${process.env.NEXT_PUBLIC_SHOPIFY_CHECKOUT_DOMAIN}${pathname}${search}`
    window.location.href = redirect
  }

  const value = {
    ...useCartValues,
    goToCheckout,
  }

  return (
    <ShopifyContext.Provider value={value}>{children}</ShopifyContext.Provider>
  )
}
