import { gql } from 'graphql-tag'
import { CartLineUpdateInput, CartResponse } from '../../types'
import { cartFragment } from '../../../../graphql'

export interface CartLinesUpdateInput {
  cartId: string
  lines: CartLineUpdateInput[]
}

export type CartLinesUpdateResponse = CartResponse<'cartLinesUpdate'>

export const CART_LINES_UPDATE = gql`
  mutation CartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      userErrors {
        __typename
        code
        field
        message
      }
      cart {
        ...CartFragment
      }
    }
  }
  ${cartFragment}
`
