import { createClient } from '@sanity/client'
import { Document } from '../types'
import { withTypenames } from '../utils'

export const sanityClient = createClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  useCdn: false, // `false` if you want to ensure fresh data
  useProjectHostname: true,
  apiVersion: '2024-01-01',
})

export const sanityClientForPreview = createClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  token: process.env.SANITY_READ_TOKEN,
  useCdn: false,
  useProjectHostname: true,
  apiVersion: '2024-01-01',
  withCredentials: true,
})

export const sanityQuery = async <R = Document[]>(
  query: string,
  params?: Record<string, any>,
): Promise<R> => {
  const results = await sanityClient.fetch<R>(query, params || {})
  // @ts-ignore
  return withTypenames<R>(results)
}

export const sanityPreviewQuery = async <R = Document[]>(
  query: string,
  params?: Record<string, any>,
): Promise<R> => {
  const results = await sanityClientForPreview.fetch<R>(query, params || {})

  // @ts-ignore
  return withTypenames<R>(results)
}
