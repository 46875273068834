import { gql } from 'graphql-tag'
import { Cart } from '../../types'
import { cartFragment } from '../../../../graphql'

export interface CartFetchInput {
  id: string
}

export interface CartFetchResponse {
  node: Cart
}

export const CART_FETCH = gql`
  query CartQuery($id: ID!) {
    node(id: $id) {
      id
      ... on Cart {
        ...CartFragment
      }
    }
  }
  ${cartFragment}
`
