import { gql } from 'graphql-tag'
import { CartResponse } from '../../types'
import { cartFragment } from '../../../../graphql'

export interface CartNoteUpdateArgs {
  cartId: string
  note: string
}

export type CartNoteUpdateResponse = CartResponse<'cartNoteUpdate'>

export const CART_NOTE_UPDATE = gql`
  mutation AddNote($cartId: ID!, $note: String!) {
    cartNoteUpdate(cartId: $cartId, note: $note) {
      userErrors {
        __typename
        code
        field
        message
      }
      cart {
        ...CartFragment
      }
    }
  }
  ${cartFragment}
`
