import * as React from 'react'
import { DocumentNode } from 'graphql'
import {
  UserError,
  QueryFunction,
  Cart,
  CartLineInput,
  CartLineUpdateInput,
} from '../types'
import { defaultQueries, CartCreateInput, CartCreateResponse } from './queries'
import { cartReducer } from './reducer'
import {
  STARTED_REQUEST,
  APPLIED_DISCOUNT,
  ADDED_LINE_ITEMS,
  UPDATED_LINE_ITEMS,
  CART_CLEARED,
  CREATED_CART,
  UseCartValues,
  FETCHED_CART,
  ADDED_NOTE,
  ADDED_ATTRIBUTES,
  // RECEIVED_ERRORS,
} from './types'
import { VIEWER_CART_TOKEN, setCookie, getCookie } from '../../../utils'
import {
  ShopifyStorefrontAttributeInput,
  ShopifyStorefrontCartLine,
  ShopifyStorefrontCartLineInput,
  ShopifyStorefrontCountryCode,
} from '../../../types/generated-shopify'
import { useCountry } from '../../CountryProvider'
import { unwindEdges } from '@good-idea/unwind-edges'
import {
  CartDiscountCodesUpdateInput,
  CartDiscountCodesUpdateResponse,
} from './queries/cartDiscountCodesUpdate'
import { CartFetchInput, CartFetchResponse } from './queries/cartFetch'
import { CartLinesAddInput, CartLinesAddResponse } from './queries/cartLinesAdd'
import {
  CartLinesUpdateInput,
  CartLinesUpdateResponse,
} from './queries/cartLinesUpdate'
import {
  CartAttributesUpdateArgs,
  CartAttributesUpdateInput,
  CartAttributesUpdateResponse,
} from './queries/cartAttributesUpdate'
import {
  CartNoteUpdateArgs,
  CartNoteUpdateResponse,
} from './queries/cartNoteUpdate'

const { useReducer, useEffect, useState } = React

/**
 * API
 */

export interface UseCartCheckoutConfig {
  /* dummy config for now */
  foo?: string
}

interface UseCartCheckoutArguments {
  query: QueryFunction
  queries?: Partial<UseCartCheckoutQueries>
  config?: Partial<UseCartCheckoutConfig>
}

export interface UseCartCheckoutQueries {
  CART_CREATE: string | DocumentNode
  CART_FETCH: string | DocumentNode
  CART_ATTRIBUTES_UPDATE: string | DocumentNode
  CART_LINE_ITEMS_ADD: string | DocumentNode
  CART_LINE_ITEMS_UPDATE: string | DocumentNode
  CART_DISCOUNT_CODES_UPDATE: string | DocumentNode
  CART_NOTE_UPDATE: string | DocumentNode
}

const initialState = {
  ready: false,
  loading: true,
  userErrors: [],
  cart: undefined,
}

/**
 * Helper Functions
 */

const setViewerCartCookie = (token: string) => {
  // const cleanToken = token?.split('?')[0]

  setCookie(VIEWER_CART_TOKEN, token)
}
const getViewerCartCookie = () => getCookie(VIEWER_CART_TOKEN)

interface CartAndErrors {
  cart?: Cart
  userErrors?: UserError[]
}

export const useCartCheckout = ({
  queries: userQueries,
  query,
}: UseCartCheckoutArguments): UseCartValues => {
  const {
    CART_CREATE,
    CART_FETCH,
    CART_ATTRIBUTES_UPDATE,
    CART_LINES_ADD,
    CART_LINES_UPDATE,
    CART_DISCOUNT_CODES_UPDATE,
    CART_NOTE_UPDATE,
  } = {
    ...defaultQueries,
    ...userQueries,
  }

  // const getVariantLine = (item: ShopifyStorefrontCartLine): CartLineInput => {
  //   let shopifyId = item.id

  //   // Ensure the ID is in the correct Shopify GID format
  //   if (!shopifyId.startsWith('gid://shopify/ProductVariant/')) {
  //     const itemId = Buffer.from(item.id, 'base64')
  //       .toString()
  //       .split('?')[0]
  //       .split('/')
  //       .pop()

  //     shopifyId = `gid://shopify/ProductVariant/${itemId}`
  //   }

  //   const attributes =
  //     item.attributes && item.attributes.length > 0
  //       ? item.attributes.map((a) => ({
  //           key: a.key,
  //           value: a.value || '',
  //         }))
  //       : []

  //   return {
  //     merchandiseId: shopifyId,
  //     quantity: item.quantity,
  //     attributes,
  //   }
  // }

  const getVariantLine = (
    item: ShopifyStorefrontCartLine,
  ): ShopifyStorefrontCartLineInput => {
    const id = item.merchandise.id ? item.merchandise.id : item.id

    const itemId =
      id.startsWith('gid') && id.includes('cart=')
        ? id.toString().split('?')[0].split('/').pop()
        : Buffer.from(id, 'base64').toString().split('?')[0].split('/').pop()
    const shopifyId =
      id.startsWith('gid') && !id.includes('cart=')
        ? id
        : `gid://shopify/ProductVariant/` + itemId
    const hashedId = Buffer.from(shopifyId).toString('base64')
    // console.log('HASHED ID:', hashedId)
    // console.log('ITEM ID:', shopifyId)

    if (item.attributes && item.attributes.length > 0) {
      const attributes: ShopifyStorefrontAttributeInput[] = item.attributes.map(
        (a) => {
          return {
            key: a.key,
            value: a.value || '',
          }
        },
      )

      return {
        merchandiseId: shopifyId,
        quantity: item.quantity,
        attributes: attributes,
      }
    } else {
      return {
        merchandiseId: shopifyId,
        quantity: item.quantity,
      }
    }
  }

  /**
   * State
   */

  const [state, dispatch] = useReducer(cartReducer, initialState)
  const { loading, currentCountry } = useCountry()
  const [countryCode, setCountryCode] =
    useState<ShopifyStorefrontCountryCode>(currentCountry)

  // console.log('state', state)
  /**
   * Base Methods
   *
   * These methods query the Shopify API. Their names should match the names of the
   * available queries & mutations.
   *
   */

  const cartCreate = async (variables?: CartCreateInput) => {
    if (state.cart && state.cart.buyerIdentity.countryCode == countryCode)
      return { cart: state.cart }
    const result = await query<CartCreateResponse, CartCreateInput>(
      CART_CREATE,
      variables || { countryCode: countryCode },
    )

    const { cartCreate: cartCreateResponse } = result

    // const strippedId = cartCreateResponse.cart?.id.split('?')[0] // Split at '?' and take the first part

    if (cartCreateResponse.cart)
      setViewerCartCookie(cartCreateResponse.cart?.id)
    dispatch({ type: CREATED_CART, ...cartCreateResponse })
    return cartCreateResponse
  }

  const getOrCreateCart = async (variables?: CartCreateInput) => {
    // if state checkout exists and matches countryCode, return it
    if (state.cart && state.cart.buyerIdentity.countryCode == countryCode) {
      // state.checkout exists and matches countryCode
      // console.log('state checkout exists and matches countryCode')
      return {
        cart: state.cart,
        userErrors: state.userErrors,
      }
      // otherwise, create a new checkout with updated country code and context
    } else {
      // console.log('create a new checkout with updated country code and context')
      // if state checkout had line items, add them to the new checkout
      if (state.cart && state.cart.lines) {
        // console.log(
        //   'state checkout had line items, add them to the new checkout',
        // )
        // state checkout had line items, add them to the new checkout
        const lines = unwindEdges(state.cart.lines)[0]
        const cartLineInputs: CartLineInput[] = lines.map((item) =>
          getVariantLine(item as ShopifyStorefrontCartLine),
        )

        const variables: CartCreateInput = {
          lines: cartLineInputs,
          countryCode: countryCode,
        }
        return cartCreate(variables)
      } else {
        // console.log('state.checkout', state.checkout)
        // create new checkout with updated country code
        return cartCreate({ countryCode: countryCode })
      }
    }
  }

  const fetchCart = async () => {
    const cartToken = getViewerCartCookie()

    if (cartToken) {
      /* If a token exists, fetch it from Shopify */
      const variables = { id: cartToken, countryCode: currentCountry }
      const result = await query<CartFetchResponse, CartFetchInput>(
        CART_FETCH,
        variables,
      )
      const cart = result ? result.node : undefined
      // console.log(
      //   'fetched checkout using token, with updated country code',
      //   checkout,
      // )

      const lines = unwindEdges(cart?.lines)[0]
      const cartLineInputs: CartLineInput[] = lines.map((item) =>
        getVariantLine(item as ShopifyStorefrontCartLine),
      )

      // console.log('checkoutLineItemInputs:', checkoutLineItemInputs)
      /* If checkout countryCode doesn't match CountryProvider countryCode, create a new checkout */
      // if (
      //   checkout &&
      //   checkout.lineItems &&
      //   countryCode &&
      //   checkout?.buyerIdentity.countryCode != countryCode
      // ) {
      //   console.log(
      //     'country code mismatch, recreating checkout',
      //     checkout,
      //     countryCode,
      //   )
      //   // checkout.buyerIdentity.countryCode = currentCountry
      //   const lineItems = unwindEdges(checkout.lineItems)[0]

      //   const checkoutLineItemInputs: CheckoutLineItemInput[] = lineItems.map(
      //     (item) => getVariantLineItem(item),
      //   )

      //   const variables: CheckoutCreateInput = {
      //     lineItems: checkoutLineItemInputs,
      //     countryCode: countryCode,
      //   }
      //   const updatedCheckout = await getOrCreateCheckout(variables)
      //   console.log('UPDATED CHECKOUT', updatedCheckout)
      // } else {
      //   dispatch({ type: FETCHED_CHECKOUT, checkout })
      // }
      if (cart && cart.buyerIdentity.countryCode !== currentCountry) {
        // console.log(
        //   'Country code mismatch, recreating checkout due to country change.',
        // )
        const lines = unwindEdges(cart.lines)[0]
        const cartLineInputs: CartLineInput[] = lines.map((item) =>
          getVariantLine(item as ShopifyStorefrontCartLine),
        )

        return getOrCreateCart({
          lines: cartLineInputs,
          countryCode: currentCountry,
        })
      } else {
        // console.log('Using existing checkout fetched with token.')

        dispatch({ type: FETCHED_CART, cart })
      }
    } else {
      // console.log('No checkout token found, creating a new checkout.')
      /* When no token exists, dispatch this to set "loading" to false. */
      /* This might deserve its own action type, "NOTHING_TO_FETCH" */
      getOrCreateCart({ countryCode: currentCountry })
      // dispatch({ type: FETCHED_CHECKOUT, checkout: undefined })
    }
  }

  const cartLineItemsAdd = async (lines: CartLineInput[]) => {
    const cart = state.cart
    if (!cart)
      throw new Error(
        'cartLineItemsAdd was called before a checkout was created.',
      )
    dispatch({ type: STARTED_REQUEST })

    const variables = { lines: lines, cartId: cart.id }
    const result = await query<CartLinesAddResponse, CartLinesAddInput>(
      CART_LINES_ADD,
      variables,
    )
    dispatch({ type: ADDED_LINE_ITEMS, ...result.cartLinesAdd })
  }

  const cartLineItemsUpdate = async (lines: CartLineUpdateInput[]) => {
    const cart = state.cart

    if (!cart) throw new Error('There is no checkout to update')
    dispatch({ type: STARTED_REQUEST })

    const result = await query<CartLinesUpdateResponse, CartLinesUpdateInput>(
      CART_LINES_UPDATE,
      {
        lines: lines,
        cartId: cart.id,
      },
    )

    dispatch({
      type: UPDATED_LINE_ITEMS,
      ...result.cartLinesUpdate,
    })
  }

  const cartDiscountCodesUpdate = async (discountCodes: string[]) => {
    const { cart } = await getOrCreateCart()
    if (!cart)
      throw new Error(
        'cartDiscountCodesUpdate was called before a cart was created.',
      )
    dispatch({ type: STARTED_REQUEST })
    const result = await query<
      CartDiscountCodesUpdateResponse,
      CartDiscountCodesUpdateInput
    >(CART_DISCOUNT_CODES_UPDATE, {
      discountCodes: discountCodes,
      cartId: cart.id,
    })
    dispatch({
      type: APPLIED_DISCOUNT,
      ...result.cartDiscountCodesUpdate,
    })
  }

  const cartAttributesUpdate = async (input: CartAttributesUpdateInput) => {
    const { cart } = await getOrCreateCart()
    if (!cart)
      throw new Error(
        'cartAttributesUpdate was called before a checkout was created.',
      )
    dispatch({ type: STARTED_REQUEST })
    const result = await query<
      CartAttributesUpdateResponse,
      CartAttributesUpdateArgs
    >(CART_ATTRIBUTES_UPDATE, {
      cartId: cart.id,
      attributes: input.attributes,
    })

    dispatch({
      type: ADDED_ATTRIBUTES,
      ...result.cartAttributesUpdate,
    })
  }

  const cartNoteUpdate = async (input: string) => {
    const { cart } = await getOrCreateCart()
    if (!cart)
      throw new Error(
        'cartNoteUpdate was called before a checkout was created.',
      )
    dispatch({ type: STARTED_REQUEST })
    const result = await query<CartNoteUpdateResponse, CartNoteUpdateArgs>(
      CART_NOTE_UPDATE,
      {
        cartId: cart.id,
        note: input,
      },
    )
    dispatch({
      type: ADDED_NOTE,
      ...result.cartNoteUpdate,
    })
  }

  /**
   * Shortcut Methods
   *
   * These methods implement the base methods to provide simpler "shortcut"
   * functions with a simpler API
   */

  /* Adds a single line item to the checkout */
  const addLineItem = async (lineItem: CartLineInput) =>
    cartLineItemsAdd([lineItem])

  /* Updates a single line item */
  const updateLineItem = async (lineItem: CartLineUpdateInput) =>
    cartLineItemsUpdate([lineItem])

  const addNote = async (note: string) => cartNoteUpdate(note)

  const addAttributes = async (input: CartAttributesUpdateInput) =>
    cartAttributesUpdate(input)

  const refetchCart = async () => {
    fetchCart()
  }

  /* Clears the cart */
  const clearCart = async () => dispatch({ type: CART_CLEARED })

  /**
   * Effects
   */

  useEffect(() => {
    if (loading) return
    setCountryCode(currentCountry)
    fetchCart()
  }, [currentCountry, loading])

  const value = {
    ...state,
    /* Base Methods */
    cartCreate,
    cartLineItemsAdd,
    cartLineItemsUpdate,
    cartAttributesUpdate,
    cartDiscountCodesUpdate,
    cartNoteUpdate,

    /* Shortcut Methods */
    addLineItem,
    updateLineItem,
    clearCart,
    refetchCart,
    addNote,
    addAttributes,
  }

  return value
}
